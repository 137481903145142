import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import React, { useCallback, useEffect, useState } from 'react';
import { LivePreviewMappedFeatureCards } from '@cms-components/feature-cards/feature-cards';
import { LivePreviewHeader } from '@shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import { LivePreviewSelect } from '@shared/contentstack/live-preview/widgets/live-preview-widgets';
import { FEATURED_CARDS_CATEGORIES_MOCK } from '@shared/contentstack/live-preview/mock-data/featured-cards-mock';

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
];

const FeaturedCardsPreviewPage = (props: any) => {
  const { pageContext } = props;
  const [bgColor, setBgColor] = useState('light');
  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'featured_cards',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    setLivePreviewData(response[0][0]);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Background Color"
          options={color_options}
          setSelectedItem={setBgColor}
        />
      </LivePreviewHeader>

      {!!livePreviewData && (
        <LivePreviewMappedFeatureCards
          {...{
            ...livePreviewData,
            background_color: bgColor,
            display_categories: FEATURED_CARDS_CATEGORIES_MOCK,
          }}
        />
      )}
    </>
  );
};

export default FeaturedCardsPreviewPage;
